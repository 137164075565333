import React from 'react';

import DashboardLayout from 'layouts/DashboardLayout';
import MainLayout from 'layouts/MainLayout';
import DataContainer from 'components/Data-Container/data-container';
import LoginPage from 'containers/auth/login-page';
import ForgotPasswordPage from 'containers/auth/forgot-password';
import AdminDash from 'containers/admin/admin-dashboard';
import RegistrationForm from 'containers/admin/registration-form';
import HomePage from 'containers/homepage/landingpage';
import ResetPassword from 'containers/auth/reset-password';
import DemoRequestPage from 'containers/auth/demo-request-page';

const routes = [
  {
    path: 'app',
    element: <DashboardLayout />,
    children: [
      { path: ':component/*', element: <DataContainer />,},
    ]
  },
  {
    path: '/',
    element: <MainLayout />,
    children: [
      { path: 'login', element: <LoginPage /> },
      { path: 'admin', element: <AdminDash /> },
      { path: 'admin/register', element: <RegistrationForm /> }, 
      { path: 'demo/request', element: <DemoRequestPage /> },
      { path: 'recover', element: <ForgotPasswordPage /> },
      { path: 'reset/password', element: <ResetPassword /> },
      { path: '/', element: <HomePage /> },
      { path: '/:features', element: <HomePage /> },
    ]
  }
];

export default routes;
