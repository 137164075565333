import React from 'react';
import tw from 'twin.macro';
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from './helpers/AnimationRevealPage.js';
import Hero from './layouts/TwoColumnWithInput';

import Features from './layouts/ThreeColWithSideImage';
import FeatureWithSteps from './layouts/TwoColWithSteps';
import Pricing from './pricing/ThreePlans';
import FAQSection from './layouts/SingleCol';
import Footer from './layouts/FiveColumnDark';
import macHeroScreenshotImageSrc from './images/hero-screenshot-2.png';

export default function HomePage() {
  const Subheading = tw.span`uppercase tracking-widest font-bold text-primary-500`;
  const HighlightedText = tw.span`text-primary-500`;

  return (
    <AnimationRevealPage>
      <Hero roundedHeaderButton={true} />
      <Features id='#features'
        subheading={<Subheading>Features</Subheading>}
        heading={
          <>
            We have Amazing <HighlightedText>Service.</HighlightedText>
          </>
        }
      />
      <FeatureWithSteps
        subheading={<Subheading>STEPS</Subheading>}
        heading={
          <>
            Easy to <HighlightedText>Get Started.</HighlightedText>
          </>
        }
        textOnLeft={false}
        imageSrc={macHeroScreenshotImageSrc}
        imageDecoratorBlob={true}
        decoratorBlobCss={tw`xl:w-40 xl:h-40 opacity-15 -translate-x-1/2 left-1/2`}
      />
      <Pricing id='pricing'
        subheading={<Subheading>Pricing</Subheading>}
        heading={
          <>
            Reasonable & Flexible <HighlightedText>Plans.</HighlightedText>
          </>
        }
      />

      <FAQSection
        subheading={<Subheading>FAQS</Subheading>}
        heading={
          <>
            You have <HighlightedText>Questions ?</HighlightedText>
          </>
        }
        faqs={[
          {
            question: 'What is covered in the plan ?',
            answer:
              'Each plan atleast provides dispatch management including accounting and driver settlement. It also includes generating pdf receiving and making payments. Premium & Enterprise provides other useful information including notifications, driver mobile apps.'
          },
          {
            question: 'Can we upgrade to different plan ?',
            answer:
              'Yes, you can upgrade to different plan. The new plan will kick in as soon as you make the change.'
          },
          {
            question: 'Is my data safe ?',
            answer:
              'We leverage Amazon cloud which is one of the comprehensive, reliable and secure service in the world. Your data is never share with anyone else.'
          },
          {
            question: 'I need to close my account, what happens to my data ?',
            answer:
              'We are confident that once you enjoy working with our Software, you will not leave us. In scenario of closing the account, your data is secured and never touched. You can always activate same account. You can also download your data in CSV format.'
          },
          {
            question: 'Where can I reach you for support ?',
            answer:
              'Email is the best way to reach our tech and sales support teams.'
          }
        ]}
      />      
      <Footer />
    </AnimationRevealPage>
  );
}